import { useScreenType } from 'hooks/useScreenType'
import React, { useState } from 'react'
import style from './style.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import menu from '../../../assets/menu.jpg';
import MobileSideMenu from './MobileSideMenu';
import Sidebar from "react-sidebar";
import stores from 'assets/stores.png';
import loginicon from "assets/user.png";
import searchicon from "assets/search.png";
import SearchField from './SearchField';
import whatsapp from "../../../assets/whatsapp.svg"
import facebook from "../../../assets/facebook.svg"

const styles = {
    sidebar: {
      backgroundColor: 'rgb(80, 80, 80, 0.8)',
      color: '#FFFFFF',
      zIndex: 2,
      position: "absolute",
      top: 0,
      bottom: 0,
      width: '60%',
      transition: "transform .3s ease-out",
      WebkitTransition: "-webkit-transform .3s ease-out",
      willChange: "transform",
      overflowY: "auto",
      borderRadius: '15px 0 0 15px',
    },
    content: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: "auto",
      WebkitOverflowScrolling: "touch",
      transition: "left .3s ease-out, right .3s ease-out"
    },
    overlay: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      visibility: "hidden",
      transition: "opacity .3s ease-out, visibility .3s ease-out",
      backgroundColor: "rgba(0,0,0,.35)"
    },
    dragHandle: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      bottom: 0
    }
};

const menuIconStyles = {
    display: 'inline-block',
    position: 'absolute',
    right: '20px',
    top: '21px',
}


const TopMenuLayout = ({ activeTopMenu, setActiveTopMenu, getCart,
                         closeStore, isPackItemUpdating, isCartItemUpdating,
                         userParams, userToken, shopsData, userId }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const menuBtns = [
        { name: 'RetPolicy', label: 'מדיניות ביטולים' },
        { name: 'PPolicy', label: 'מדיניות פרטיות' },
        { name: 'Terms', label: 'תקנון' },
        { name: 'About', label: 'אודות' },
        { name: 'Contacts', label: 'יצירת קשר' },
        { name: 'History', label: 'היסטוריית הזמנות' },
        { name: 'ShopMenu', label: 'תפריט' },
    ];

    const renderSidebarContent = (pageName) => {
        const gotoPage = (item) => {
            setMenuIsOpen(false);
            setActiveTopMenu(item.name);
        }

        const gotoStoresPage = () => {
            if(isPackItemUpdating || isCartItemUpdating){
              getCart().cartItemList.pop();
            }
        
            closeStore();
        }

        const gotoRegistrationPage = () => {
            setMenuIsOpen(false);
            return !userToken ? setActiveTopMenu("Registration") : null;
        }

        return(
            <div className='sidebar-content'>
                {/* <div className={style.SocialIcons}>
                    <img alt="" src={facebook} width="40px" />
                    <img alt="" src={whatsapp} width="38px" />
                </div> */}
                <div className='wrappIcons' style={{display: 'flex', justifyContent: 'space-between', fontSize: '10px', padding: '5px 0 15px 0', borderBottom: '2px solid #808080'}}>
                    <div className={style.DivLogin} style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                        <div
                            className={style.LogIconDiv}
                            style={{marginRight: '5px'}}
                            onClick={gotoRegistrationPage}
                            title={userToken ? "מחובר/ת" : "התחבר/י"}
                        >
                            <LazyLoadImage
                            effect='blur'
                            className={style.LoginIcon}
                            src={loginicon}
                            alt="login"
                            width='40px'
                            />
                        </div>

                        <div className={style.LoginText} style={{marginRight: '5px'}}>
                            {userToken ? (
                            <>
                                <div>שלום,</div>
                                <div>{userParams.name}</div>
                            </>
                            ) : (
                            <>
                                <div>שלום אורח/ת</div>
                                <div className={style.LoginHere}>התחבר/י</div>
                            </>
                            )}
                        </div>
                    </div>

                    {
                        shopsData &&
                            <div className='allStores' style={{marginLeft: '5px'}}>
                                <div className={style.StoresImg}>
                                    <img width='40px' src={stores} alt="Stores" onClick={gotoStoresPage} /> 
                                </div>
                            </div>
                    }
                </div>

                <div className='item-list' style={{display: 'flex', flexDirection: 'column-reverse'}}>
                {
                    menuBtns.map((item, idx) => {
                        return <div onClick={() => item.name === 'History' ? null : gotoPage(item)}
                                    key={idx} 
                                    style={{borderBottom: '2px solid #808080',
                                            padding: '10px 0 10px 0', fontWeight: 500,
                                            fontSize: '20px', color: item.name === 'History' ? '#808080' : '#FFFFFF'}}
                                >
                                    {item.label}
                                </div>
                    })
                }
                </div>
            </div>
        )
    }

    const isfullscreen = useScreenType() === 'fullscreen';
    return (
        <div className={style.Container}>
        {
            !isfullscreen
            ?
            
                <div id="" className={style.AppTopMenu}>
                    {/* <div className={style.SearchContainer}> */}
                    <SearchField />
                    {/* </div> */}
                    {/* <input className={style.searchbox} type="text" style={{backgroundImage: searchBg}}/> */}
                    {
                        menuBtns.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {item.name === 'History' && userId === null 
                                    ?
                                        null
                                    :
                                        <div key={`ind_${index}`}>
                                            <div className={activeTopMenu == item.name ?
                                                `${style.menubtn} ${style.menubtnActive}` :
                                                style.menubtn} onClick={() => setActiveTopMenu(item.name)} >{item.label}</div>
                                        </div>
                                    }
                                </React.Fragment>
                                
                                /* <div key={`ind_${index}`}>
                                    <div className={activeTopMenu == item.name ? `${style.menubtn} ${style.menubtnActive}` : style.menubtn} onClick={() => setActiveTopMenu(item.name)} >{item.label}</div>
                                </div> */
                            )
                        })
                    }
                </div>
            :
                <>
                    <Sidebar
                        sidebar={renderSidebarContent()}
                        open={menuIsOpen}
                        transitions={true}
                        onSetOpen={setMenuIsOpen}
                        styles={styles}
                        pullRight={true}
                        touch={true}
                    >
                    
                        <div style={menuIconStyles} onClick={() => setMenuIsOpen(true)}>
                            <LazyLoadImage
                                effect='blur'
                                src={menu}
                                alt={'menu'}
                                width='40px'
                            />
                        </div>
                    </Sidebar>

                    <div style={{position: 'absolute', right: '14px', top: '67px'}}>
                        <SearchField />
                    </div>
                </>
        }

        </div>
    )
}

export default TopMenuLayout
