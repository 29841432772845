import React from "react";
import { observer, inject } from "mobx-react";
import Sidebar from "react-sidebar";
 
// const mql = window.matchMedia(`(min-width: 850px)`);
const styles = {
  root: {
    // position: "absolute",
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // overflow: "hidden",
    // backgroundColor: 'rgb(0, 0, 0, 0.5)',
  },
  sidebar: {
    backgroundColor: 'rgb(255, 255, 255, 0.5)',
    zIndex: 200,
    position: "absolute",
    top: 0,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto"
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out",
  },
  overlay: {
    zIndex: 100,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)"
  },
  dragHandle: {
    zIndex: 100,
    position: "fixed",
    top: 0,
    bottom: 0
  }
};
@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class MobileSideMenu extends React.Component {
      render() {
        return (
          <Sidebar
            sidebar={<b>Sidebar content <div style={{color: 'white'}}></div></b>}
            // docked={this.props.menuIsOpen}
            open={this.props.menuIsOpen}
            transitions={true}
            onSetOpen={this.props.setMenuIsOpen}
            styles={styles}
            pullRight={true}
            touch={false}
          >
                  <button onClick={() => this.props.setMenuIsOpen(true)}>
          Open sidebar
        </button>
          </Sidebar>
        );
      }
    }

export default MobileSideMenu;
